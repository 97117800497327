#home {
  width: 100%;
  height: 100%;
}

.first_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  border-top: 4mm ridge rgba(36, 86, 203, 0.6);
  overflow: hidden;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure particles are behind other content */
}
.second_page {
  height: 100%;
}

.yap {
  font-size: larger;
}

.third_page {
  height: 100%;
}
.about_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.projects_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
.introduction {
  font-size: 25px;
  align-self: center;
}

.introduction > * {
  margin: 0px;
}

.projects_container {
  max-width: 600px; /* Set a maximum width to keep it from getting too wide */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding to ensure content isn't touching the edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
  border-radius: 8px; /* Optional: Round the corners */
}

.project_image {
    width: 90%;
}